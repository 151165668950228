<template>
  <div id="contact-person" class="relative">
    <div class="header text-center bg-dark_red pt-10 pb-36 shadow-2xl sm:hidden">
      <h1 class="font-montserrat font-bold text-4xl text-white">
        {{ $t("contact.contactPerson.word1") }}
      </h1>
      <h1 class="font-montserrat font-bold text-4xl text-white">
        {{ $t("contact.contactPerson.word2") }}
      </h1>
    </div>
    <div class="relative text-center -top-28 sm:top-0 sm:flex sm:flex-row-reverse sm:bg-dark_red sm:px-64 sm:pt-24
                sm:pb-32 sm:justify-end">
      <div class="relative  ">
        <img
          class="w-56 h-56 rounded-3xl mx-auto border-1 border-dark_red sm:mx-0 sm:rounded-tl-none sm:rounded-bl-none
            object-cover contact-person-photo"
          :src="$t('contact.contactPerson.url')"
          :alt="$t('contact.contactPerson.alt')"
        />
        <h2 class="font-roboto text-3xl text-dark_red relative my-6 sm:hidden ">
          Sergiu Preda
        </h2>
        <div class="hidden sm:block absolute z-20 -top-24 -left-10 right-shadow w-10" style="height: 400px"></div>
      </div>
      <div class="sm:relative sm:w-2/3">
        <div class="my-4 sm:flex">
          <img
            class="icon mx-auto w-8 h-8 rounded-full p-1 mb-1 sm:hidden"
            src="../../assets/icons/person.svg"
            alt="Person Icon"
          />
          <div class="hidden bg-black py-2 pl-5 pr-3 rounded-r-2xl sm:block">
            <img
              class="w-7 h-7 object-contain"
              src="../../assets/icons/person-contact.svg"
              alt="Person Icon"
            />
          </div>
          <h3 class="text-black text-sm font-roboto opacity-80 sm:text-white sm:font-light sm:text-2xl sm:ml-5 sm:mt-1">
            <span class="hidden sm:inline"> Sergiu Preda - </span> {{ $t("about.aboutBoard.Board[0].position") }}
          </h3>
        </div>
        <div class="my-4" >
          <a href="mailto:sergiu.preda@eestec.ro" class="sm:flex">
              <img
                class="mx-auto w-8 h-8 p-1 object-contain mb-1 icon rounded-full sm:hidden"
                src="../../assets/icons/email.svg"
                alt="Email Icon"
              />
              <div class="hidden bg-black py-2 pl-5 pr-3 rounded-r-2xl sm:block">
                <img
                  class="w-7 h-7 object-contain"
                  src="../../assets/icons/email-contact.svg"
                  alt="Email Icon"
                />
              </div>
            <h3 class="text-black text-sm font-roboto opacity-80 sm:text-white sm:font-light sm:text-2xl sm:ml-5
                        sm:mt-1">
              sergiu.preda@eestec.ro
            </h3>
          </a>
        </div>
        <div>
          <a href="tel:+40770897452" class="sm:flex">
            <img
              class="icon mx-auto w-8 h-8 rounded-full p-1 mb-1 sm:hidden"
              src="../../assets/icons/phone.svg"
              alt="Phone Icon"
            />
            <div class="hidden bg-black py-2 pl-5 pr-3 rounded-r-2xl sm:block">
              <img
                class="w-7 h-7 object-contain"
                src="../../assets/icons/phone-contact.svg"
                alt="Phone Icon"
              />
            </div>
            <h3 class="text-black text-sm font-roboto opacity-80 sm:text-white sm:font-light sm:text-2xl sm:ml-5
                        sm:mt-1">
              +40 770 897 452
            </h3>
          </a>
        </div>
        <div class="hidden sm:block absolute z-20 -top-24 -left-10 right-shadow w-10" style="height: 400px"></div>
      </div>
    </div>
    <ScrollDownArrowVue/>
  </div>
</template>

<script>
import ScrollDownArrowVue from '../global/Desktop/ScrollDownArrow.vue';

export default {
  components: {
    ScrollDownArrowVue,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
}

.header {
  box-shadow: 0px 5px 3px 0px rgba(0,0,0,0.3);
}

.right-shadow {
  box-shadow: 16px 0px 16px -10px rgba(51,51,51,0.5);
}

.contact-person-photo {
  object-position: center;
}
</style>
