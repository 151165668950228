<template>
  <div id="contact-page">
    <ContactHero/>
    <ContactPerson/>
    <Newsletter/>
  </div>
</template>

<script>
import ContactHero from "../components/contact/ContactHero.vue";
import ContactPerson from "../components/contact/ContactPerson.vue";
import Newsletter from "../components/contact/Newsletter.vue";

export default {
  components: {
    ContactHero,
    ContactPerson,
    Newsletter,
  },
  data() {
    return {
      //comentariu ca sa nu dea ESLint warning
    };
  },
};
</script>

<style lang="scss" scoped>
//comentariu ca sa nu dea ESLint warning
</style>
