<template>
  <div id="contact-hero" class="bg-dark_red sm:flex sm:pt-44 sm:px-64 sm:justify-start">
    <div class="image-container w-screen relative flex justify-center items-center sm:hidden">
      <div class="hero-image absolute w-full h-full top-0 left-0"></div>
      <div class="red-overlay w-full h-full bg-black opacity-70 absolute top-0 left-0 z-10"></div>
      <div class="flex z-20 flex-wrap w-2/3 justify-center gap-y-16">
        <img src="../../assets/images/Logo.png" alt="EESTEC Bucuresti Logo" class="w-36 z-20">
      </div>
    </div>
    <div class="rounded-top-borders contact-us-section pt-10 bg-dark_red relative bottom-10 z-20 sm:w-2/3">
      <h1 class="text-white font-montserrat text-2xl text-center mb-8 sm:font-bold sm:text-3xl sm:ml-24 sm:text-left">
        {{ $t("contact.contactHero.title") }}
      </h1>
      <div class="email-section flex items-center mb-10 sm:mb-6">
        <div class="bg-black py-2 pl-5 pr-3 rounded-r-2xl">
          <img src="../../assets/icons/email-contact.svg" alt="Phone Icon" class="w-5 h-5 sm:w-7 sm:h-7">
        </div>
        <a href="mailto: office@eestec.ro" class="link text-lg font-roboto text-white ml-5 sm:text-2xl sm:font-light">
          office@eestec.ro
        </a>
      </div>
      <div class="phone-section flex items-center mb-10 sm:mb-4">
        <div class="bg-black py-2 pl-5 pr-3 rounded-r-2xl">
          <img src="../../assets/icons/phone-contact.svg" alt="Phone Icon" class="w-5 h-5 sm:w-7 sm:h-7">
        </div>
        <a href="tel:+40770897452" class="link text-lg font-roboto text-white ml-5 sm:text-2xl sm:font-light">
          0770 897 452
        </a>
      </div>
      <div class="address-section flex items-center">
        <div class="bg-black py-2 pl-5 pr-3 rounded-r-2xl">
          <img src="../../assets/icons/location-pin-contact.svg" alt="Phone Icon" class="w-5 h-5 sm:w-7 sm:h-7">
        </div>
        <a href="https://g.page/eestec-lc-bucharest?share"
         class="text-lg font-roboto text-white ml-5 sm:text-2xl sm:font-light">
          Splaiul Independentei, <br> Nr. 313, AN204bis, UPB
        </a>
      </div>
      <div class="hidden sm:block absolute z-20 top-4 -left-10 h-96 right-shadow w-10" style="height: 400px"></div>
    </div>
    <div class="w-72 h-48 mx-auto pb-14 box-content sm:mx-0 sm:h-72 sm:w-1/3 relative no-margin" style="margin: 0px;">
      <gmap-map :center="center" :zoom="16.5" class="h-full w-full sm:mt-14" style="border-radius: 0px;">
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
        />
      </gmap-map>
      <div class="hidden sm:block absolute z-20 -top-5 -left-10 right-shadow w-10" style="height: 400px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lat: 44.43872, lng: 26.05015 },
      markers: [
        {
          position: { lat: 44.438743, lng: 26.050059 },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  height: 95vh;
}
.hero-image {
  background-image: url("https://i.imgur.com/HdpGSo7.png");
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rounded-top-borders {
  border-radius: 50px 50px 0px 0px;
}

.link {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 70vw;
    height: 1px;
    opacity: 0.5;
    background-color: white;
    @media screen and (min-width: 480px) {
      content: none;
    }
  }
}

.right-shadow {
  box-shadow: 16px 0px 16px -10px rgba(51,51,51,0.5);
}

.no-margin{
  @media screen and(min-width: 480px) {
    margin: 0!important;
  }
}
</style>
